import compose from 'core/libs/helpers/compose.js';
import {Field, withFormik} from 'formik';
import {Link} from 'gatsby';
import FormInputField from 'modules/common/FormInputField';
import Refresh from 'modules/common/Svg/Refresh';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import * as Yup from 'yup';
import emailSignIn from '../../actions/email-sign-in';
import {fixedHeight, loginButton, RefreshLoading} from '../styles.scss';

const SignInFormContainer = ({
  className = '',
  handleSubmit,
  loading,
  intl: {formatMessage},
}) => (
  <form className={className} onSubmit={handleSubmit}>
    <Field
      name="email"
      type="email"
      autoComplete="email"
      data-testid="email"
      cy="email-input"
      component={FormInputField}
      placeholder={formatMessage({id: 'label_email_adress'})}
      className={`input-reset mt3 pv3 pr3 pl2 dark-gray ba b--light-gray bw1 br2 w-100 tr ${fixedHeight}`}
    />
    <Field
      name="password"
      type="password"
      autoComplete="current-password"
      data-testid="password"
      cy="password-input"
      component={FormInputField}
      placeholder={formatMessage({id: 'label_password'})}
      className={`input-reset mt3 pv3 pr3 pl2 dark-gray ba b--light-gray bw1 br2 w-100 tr ${fixedHeight}`}
    />
    <div className="pv4 tc w-80 center">
      <div className="mb3">
        <button
          aria-label="Sign in"
          type="submit"
          data-cy="submit-btn"
          data-testid="login-btn"
          disabled={loading}
          className={`button-reset f7 f5-ns bn bg-blue fw6 white ${loginButton} br2 bg-animate hover-bg-dark-blue pointer`}
        >
          {loading ? (
            <Refresh
              className={RefreshLoading}
              width="16"
              height="16"
              color="#fff"
            />
          ) : (
            <FormattedMessage id="sign_in" />
          )}
        </button>
      </div>
      <Link className="f7 f5-ns fw6 blue dim" to="/auth/password">
        <FormattedMessage
          id="forgot_password"
          defaultMessage="I forgot my password"
        />
      </Link>
    </div>
  </form>
);

SignInFormContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  className: PropTypes.string,
  intl: PropTypes.object,
};

const enhance = compose(
  injectIntl,
  connect((state, {loading}) => ({
    loading: loading || state.auth.loading,
  })),
  withFormik({
    mapPropsToValues: () => {
      return {
        email: '',
        password: '',
      };
    },
    handleSubmit: (values, {props}) => {
      props.dispatch(emailSignIn({values}));
    },
    validationSchema: ({intl: {formatMessage}}) =>
      Yup.object().shape({
        email: Yup.string()
          .email(formatMessage({id: 'error_email_invalid'}))
          .required(formatMessage({id: 'error_email'})),
        password: Yup.string()
          .min(8, formatMessage({id: 'password_number_chars'}))
          .required(formatMessage({id: 'field_required'})),
      }),
  }),
);

export default enhance(SignInFormContainer);
