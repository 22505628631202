/* eslint-disable jsx-a11y/alt-text */
import {Link} from 'gatsby';
import Container from 'modules/common/Container';
import Head from 'modules/common/Head';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import BarmejLogo from 'theme/assets/images/barmej-logo.svg';
import KfasLogo from 'theme/assets/images/kfas-logo.svg';
import SignInForm from '../components/SignInForm';
import SignInSocial from '../components/SignInSocial';

class SignIn extends Component {
  state = {
    isJavascriptLoading: true,
  };

  componentDidMount() {
    this.setState({
      isJavascriptLoading: false,
    });
  }

  render() {
    const {
      intl: {formatMessage},
    } = this.props;
    const {isJavascriptLoading} = this.state;
    const {PARTNER} = process.env;
    return (
      <div>
        <Container className="w-90-ns w-100 center pv4 mb2 ph5-l">
          <div className="tc pb5">
            {PARTNER === 'KFAS' && (
              <img src={KfasLogo} height="100" alt="kfas logo" />
            )}
            {PARTNER === 'BARMEJ' && (
              <img src={BarmejLogo} height="50" alt="barmej logo" />
            )}
          </div>
          <div className="ba b--light-gray bw1 br2 bg-white">
            <Head
              title={formatMessage({
                id: PARTNER === 'BARMEJ' ? 'helmet_barmej' : 'partner_helmet',
              })}
            />
            <div className="tc cf">
              <Link
                to="/auth/register"
                className="fr w-50 bb bl b--light-gray bw1 pv4 f5 fw6 bg-near-white gray"
              >
                <FormattedMessage id="button_sign_up" />
              </Link>
              <Link to="/auth/login" className="fl w-50 pv4 f5 fw6 black">
                <FormattedMessage id="sign_in" />
              </Link>
            </div>
            <div className="w-80-ns w-90 center pv4">
              <p className="pv4 tc f5-ns f6">
                <FormattedMessage id="sign_in_email" />
              </p>
              <SignInForm loading={isJavascriptLoading} />
              {PARTNER === 'BARMEJ' && (
                <>
                  <p className="pv4 tc f5-ns f6 bt b--near-white">
                    <FormattedMessage id="sign_in_social" />
                  </p>
                  <SignInSocial />
                </>
              )}
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

SignIn.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(SignIn);
